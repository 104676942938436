import {customElement, bindable, inject, LogManager} from 'aurelia-framework';
import {Client} from '../api/client';
import * as _ from 'lodash';

const logger = LogManager.getLogger('object-display');

/**
 * Class to display an object or a list of objects as a table (label value)
 */
@customElement('object-display')
@inject(Client)
export class ObjectDisplay
{
    @bindable object;
    @bindable properties = [];

    constructor(client)
    {
        this.client = client;
    }

    bind()
    {
        this.columns = [];

        logger.debug("Binding changed", this);

        this.client.get(this.object.modelId + '/list', 60).then(list => {
            const columns = [];

            _.each(this.properties, (property) => {

                 let column = _.find(list.columns, (o) => { return o.property === property});

                 if (!column) {
                     logger.error('Column not found ' + property);
                     return;
                 }

                 columns.push(column);
            });

            this.columns = columns;
        });
    }
}
