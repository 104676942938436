import {customElement, bindable, inject, ObserverLocator} from 'aurelia-framework';
import {currencies} from "../currency/model/currencies.model";
import {UserClient} from "../api/user-client";

@customElement('sio-money-input')
@inject(ObserverLocator, UserClient)
export class MoneyInput
{
    @bindable({defaultBindingMode: 2}) value;

    viewChange = false;

    _value = {};

    constructor(observerLocator, userClient)
    {
        this.observerLocator = observerLocator;
        this.userClient = userClient;
    }

    async bind()
    {
        this.defaultCurrency = this.userClient.user.settings.userSettings.currency;

        this.valueChanged();
    }

    attached()
    {
        this.observerLocator.getObserver(this._value, 'amount').subscribe(this._valueChanged.bind(this));
        this.observerLocator.getObserver(this._value, 'currency').subscribe(this._valueChanged.bind(this));
    }

    valueChanged(newValue)
    {
        if (this.viewChange) {
            this.viewChange = false;
            return;
        }

        if (!this.value) {

            this._value.amount = null;
            this._value.currency = this.defaultCurrency;
            return;
        }

        //Note keep object reference here for observation
        this._value.amount = this._convertToView(this.value.amount, this.value.currency);
        this._value.currency = this.value.currency;
    }

    _valueChanged()
    {
        this.viewChange = true;

        if (this._value.amount == null || this._value.amount === '' || this._value.currency == null) {
            this.value = null;
            return;
        }

        this.value = {
            amount: this._convertToModel(this._value.amount, this._value.currency),
            currency: this._value.currency,
        };
    }

    _convertToView(amount, currency)
    {
        let decimalDigits = currencies[currency].decimal_digits;

        return amount / Math.pow(10, decimalDigits);
    }

    _convertToModel(amount, currency)
    {
        let decimalDigits = currencies[currency].decimal_digits;

        return Math.round(amount * Math.pow(10, decimalDigits));
    }
}
